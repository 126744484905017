<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">{{ mainTitle }}</h4>
        <div class="heading-elements" v-if="isProfileEditable(profile)">
          <ul class="list-inline mb-0">
            <li>
              <button v-on:click="modals.basicInfoEditModal.show = true" type="button" class="btn btn-icon btn-icon rounded-0 btn-success waves-effect waves-float waves-light" title="Add Your Profile Info">
                <i class="fas fa-pencil"></i>
              </button>
            </li>
          </ul>
        </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <tbody>
        <template v-for="item in contactProfile.singleItems">
          <tr v-if="item in this.contactProfile.items">
            <th class="text-end width-25-per" >{{ this.contactProfile.items[item].field }}</th>
            <td class="">{{ this.contactProfile.items[item].value }}</td>
            <td v-if="isPublishable(profile)">
              <div class="d-block">
                <div class="form-check form-check-success form-switch float-end" v-if="contactProfile.items[item].enablePubBtn">
                  <input
                      type="checkbox"
                      checked=""
                      class="form-check-input"
                      v-model="contactProfile.items[item].isPublished"
                      @change="changeItemVisibility(item)"
                  >
                </div>
              </div>
            </td>
          </tr>
        </template>

        </tbody>
      </table>
    </div>
  </div>

  <BasicInfoEditModal
      :contactProfile="contactProfile"
      :show="modals.basicInfoEditModal.show"
      @basicInfoSubmitted="updateBasicInfo"
      @closeModal="modals.basicInfoEditModal.show = false"
  ></BasicInfoEditModal>
</template>

<script>
import { mapMutations } from 'vuex';
import {forEach, has, isObject} from 'lodash'
import BasicInfoEditModal from "@/components/atom/Contact/BasicInfoEditModal";
import handleContact, {handleHelpers} from "@/services/modules/contact";
import {inject} from "vue";

export default {
  name: "ContactMyProfileBasicInfo",
  props: {
    profile:{
      required: true,
      type: Object
    },
    isLoginUserProfile: {
      default: false,
      type: Boolean
    }
  },
  emits: ['basicInfoUpdated'],
  components: {
    BasicInfoEditModal: BasicInfoEditModal
  },
  data: () => ({
    modals:{
      basicInfoEditModal:{
        show: false
      },
    },
    contactProfile: {
      items: {
        full_name: {
          field: 'Full Name',
          value: 'N/A',
          enablePubBtn: false,
          isPublished: false
        },
        company: {
          field: 'company',
          value: 'N/A',
          enablePubBtn: true,
          isPublished: false
        },
        job_title: {
          field: 'Job Title',
          value: 'N/A',
          enablePubBtn: true,
          isPublished: false
        },
        department: {
          field: 'Department',
          value: 'N/A',
          enablePubBtn: true,
          isPublished: false
        },
        date_of_birth: {
          field: 'Date Of Birth',
          value: 'N/A',
          enablePubBtn: true,
          isPublished: false
        },
        nid: {
          field: 'NID',
          value: 'N/A',
          enablePubBtn: true,
          isPublished: false
        },
        father_name: {
          field: 'Father Name',
          value: 'N/A',
          enablePubBtn: true,
          isPublished: false
        },
        mother_name: {
          field: 'Mother Name',
          value: 'N/A',
          enablePubBtn: true,
          isPublished: false
        },
        address: {
          field: 'Address',
          value: 'N/A',
          enablePubBtn: true,
          isPublished: false
        },
        country: {
          field: 'Country',
          value: 'N/A',
          enablePubBtn: false,
          isPublished: false
        },
        district: {
          field: 'District',
          value: 'N/A',
          enablePubBtn: false,
          isPublished: false
        },
        police_station: {
          field: 'Police Station',
          value: 'N/A',
          enablePubBtn: false,
          isPublished: false
        },
        area: {
          field: 'Area',
          value: 'N/A',
          enablePubBtn: false,
          isPublished: false
        },
        post_code: {
          field: 'Post Code',
          value: 'N/A',
          enablePubBtn: false,
          isPublished: false
        },
      },
      singleItems: [
        'full_name',
        'company',
        'job_title',
        'department',
        'date_of_birth',
        'nid',
        'father_name',
        'mother_name',
        'address',
        'country',
        'district',
        'police_station',
        'area',
        'post_code',
      ],
    },
  }),

  computed: {
    mainTitle(){
      return this.isLoginUserProfile?'Profile Basic Info': 'User Profile Basic Info'
    }
  },

  watch:{
    profile: {
      handler(value){
        this.profileItemAdapter(value)
      },
      deep: true
    }
  },

  methods:{
    ...mapMutations({
      updateUserNameToLocalStorageAfterChange: 'updateUserNameToLocalStorageAfterChange',
      setUser: 'user/setUser'
    }),
    async changeItemVisibility(key){
      let item = this.profile.contact_profile_items[key][0]
      try{
        let res = await this.updateProfileItemVisibility({
          items: [
            {
              contact_profile_item_id: item.id,
              is_public: this.contactProfile.items[key].isPublished
            }
          ]
        })

        if(res.status){
          this.showSuccess(res.message)
        }

        if(!res.status){
          this.showError(res.message)
        }

      }catch (e) {
        console.error(e)
        this.showError('Something went wrong updating visibility')
      }
    },
    checkValue(value){
      if(!!value) return value;
      return 'N/A';
    },
    formatIsPublished(isPublished){
      if(isPublished === 0) return false;

      if(isPublished === 1) return true;

      return isPublished;
    },
    async updateBasicInfo(form){
      
      this.modals.basicInfoEditModal.show = false
      this.loading = true
      let newForm = {
        profile_items: []
      };

      forEach(form, (value, key) => {

        if(!!value){
          if(has(this.contactProfile, `items.${key}`) && this.contactProfile.items[key].enablePubBtn) {
            let item = {
              field: key,
              value: value,
            }
            if(has(this.profile, `contact_profile_items.${key}`) && this.profile.contact_profile_items[key][0].id) item['id'] = this.profile.contact_profile_items[key][0].id
            newForm['profile_items'].push(item)
          }

          else newForm[key] = value
        }

      })

      try {
        let res;

        if(has(this.$route.params, 'companyId')) res = await this.updateCompanyContactProfile(this.$route.params.companyId, this.profile.contact_profile_id, newForm, this.profile.is_manual)
        else res = await this.updateContactProfile(this.profile.contact_profile_id, newForm, this.profile.is_manual)

        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.showSuccess(res.message)
          this.$emit('basicInfoUpdated')
          this.updateUserNameToLocalStorageAfterChange(form.full_name)
          this.setUser()
        }
      } catch (err) {
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response) {
          return this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }

    },
    profileItemAdapter(profile){
      let newItem = {
        full_name: this.checkValue(profile.full_name),
        country: this.checkValue(profile.location.country),
        district: this.checkValue(profile.location.district),
        police_station: this.checkValue(profile.location.police_station),
        area: this.checkValue(profile.location.area),
        post_code: this.checkValue(profile.location.post_code),
        address: {
          value: has(profile, 'contact_profile_items.address')?this.checkValue(profile.contact_profile_items.address[0].value):'N/A',
          isPublished: has(profile, 'contact_profile_items.address')?profile.contact_profile_items.address[0].is_public:false
        },

        company: {
          value: has(profile, 'contact_profile_items.company')?this.checkValue(profile.contact_profile_items.company[0].value):'N/A',
          isPublished: has(profile, 'contact_profile_items.company')?profile.contact_profile_items.company[0].is_public:false
        },
        job_title: {
          value: has(profile, 'contact_profile_items.job_title')?this.checkValue(profile.contact_profile_items.job_title[0].value):'N/A',
          isPublished: has(profile, 'contact_profile_items.job_title')?profile.contact_profile_items.job_title[0].is_public:false
        },
        department: {
          value: has(profile, 'contact_profile_items.department')?this.checkValue(profile.contact_profile_items.department[0].value):'N/A',
          isPublished: has(profile, 'contact_profile_items.department')?profile.contact_profile_items.department[0].is_public:false
        },
        date_of_birth: {
          value: has(profile, 'contact_profile_items.date_of_birth')?this.checkValue(profile.contact_profile_items.date_of_birth[0].value):'N/A',
          isPublished: has(profile, 'contact_profile_items.date_of_birth')?profile.contact_profile_items.date_of_birth[0].is_public:false
        },
        nid: {
          value: has(profile, 'contact_profile_items.nid')?this.checkValue(profile.contact_profile_items.nid[0].value):'N/A',
          isPublished: has(profile, 'contact_profile_items.nid')?profile.contact_profile_items.nid[0].is_public:false
        },
        father_name: {
          value: has(profile, 'contact_profile_items.father_name')?this.checkValue(profile.contact_profile_items.father_name[0].value):'N/A',
          isPublished: has(profile, 'contact_profile_items.father_name')?profile.contact_profile_items.father_name[0].is_public:false
        },
        mother_name: {
          value: has(profile, 'contact_profile_items.mother_name')?this.checkValue(profile.contact_profile_items.mother_name[0].value):'N/A',
          isPublished: has(profile, 'contact_profile_items.mother_name')?profile.contact_profile_items.mother_name[0].is_public:false
        },

      }

      forEach(this.contactProfile.items, (value,key) => {
        if(has(newItem, `${key}`)){
          this.contactProfile.items[key] = {
            ...value,
            value: isObject(newItem[key])?newItem[key].value:newItem[key],
            isPublished: isObject(newItem[key])?this.formatIsPublished(newItem[key].isPublished):false,
          }
        }
      })


    }
  },

  mounted() {
    this.profileItemAdapter(this.profile)
  },
  setup(){

    const showError = inject('showError');
    const showSuccess = inject('showSuccess');


    const {
      updateContactProfile,
      updateCompanyContactProfile,
      updateProfileItemVisibility,
      loading
    } = handleContact()

    const {isProfileEditable, isPublishable} = handleHelpers()
    return {
      updateContactProfile,
      updateCompanyContactProfile,
      updateProfileItemVisibility,
      isProfileEditable,
      isPublishable,
      loading,
      showError,
      showSuccess,
      forEach,
      has,
      isObject,
    }
  },
}
</script>

<style scoped>

</style>
