<template>
  <MultiItem
      :multiItem="profile.contact_profile_items"
      :isEditable="true"
      :isDeletable="true"
      :isPublishable="true"
      @onAddItem="multiItemAddItem"
  ></MultiItem>

  <div class="card" v-for="(groupItem, groupItemIndex) in contactProfile.multiItems.groupItems">
    <div class="card-header">
      <h4 class="card-title">{{ groupItem.heading }}</h4>
      <div class="heading-elements" v-if="isProfileEditable(profile)">
        <ul class="list-inline mb-0">
          <li>
            <button @click="OpenAddToGroupModal(groupItemIndex)" type="button"
                    class="btn btn-icon btn-icon rounded-0 btn-success waves-effect waves-float waves-light"
                    title="Add Your Profile Info">
              <i class="fas fa-plus"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <MultiItemGroupComponent
            :groupItem="groupItem"
            :isEditable="isProfileEditable(profile)"
            :isDeletable="isProfileEditable(profile)"
            :isPublishable="isPublishable(profile)"
            :isLoginUserProfile="isProfileEditable(profile)"
            @itemDeleted="deleteItem"
            @groupItemUpdated="saveGroupItem"
            @itemVisibilityUpdate="updateItemVisibility"
        >
        </MultiItemGroupComponent>
      </div>
    </div>

  </div>

  <MultiItemGroupModal
      :isEditing="modal.isEditing"
      :show="modal.show"
      :fields="modal.fields"
      :heading="modal.heading"
      @closeModal="modal.show = false"
      @AddGroup="GroupItemAdd"
      @UpdateGroup="GroupItemAdd"
  ></MultiItemGroupModal>
</template>

<script>
import MultiItemGroupComponent from "@/components/atom/Contact/MultiItemGroupComponent";
import MultiItemGroupModal from "@/components/atom/Contact/MultiItemGroupModal";
import MultiItem from "@/components/molecule/contact/MultiItem";
import handleContact, {handleInitData, handleHelpers} from "@/services/modules/contact";
import {has, find, forEach} from "lodash";
import {inject} from "vue";

export default {
  name: "ContactProfileMultiItemInfo",
  components: {
    MultiItemGroupComponent: MultiItemGroupComponent,
    MultiItemGroupModal: MultiItemGroupModal,
    MultiItem: MultiItem,
  },
  emits: ['profileUpdated'],
  props: {
    profile:{
      required: true,
      type: Object
    },
    isLoginUserProfile: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    modal: {
      isEditing: false,
      show: false,
      fields: [],
      heading: "Default Heading",
      groupItemIndex: 0,
    },
    contactProfile: {
      multiItems: {
        groupItems: [],
      },

    },
  }),
  watch: {
    profile: {
      handler() {
        this.updateProfileInfo()
      },
      deep: true
    },
  },
  methods: {
    isCompanyModule(){
      return has(this.$route.params, 'companyId')
    },
    updateProfileInfo() {
      if(has(this.profile, 'contact_profile_items'))
      {
        this.contactProfile.multiItems.groupItems = this.formatProfileItemsByRef(this.profile.contact_profile_items, this.contactProfile.multiItems.groupItems, this.groupItemsInitData)
      }
    },

    OpenAddToGroupModal(groupItemIndex) {
      let group = this.contactProfile.multiItems.groupItems[groupItemIndex]
      this.modal.groupItemIndex = groupItemIndex
      this.modal.fields = group.items[0].fields
      this.modal.heading = group.heading
      this.modal.show = true
    },

    GroupItemAdd(form) {
      let groupItemIndex = this.modal.groupItemIndex
      let group = this.contactProfile.multiItems.groupItems[groupItemIndex]
      let itemStructure = find(this.groupItemsInitData, (o) => o.key === group.key).items[0]
      this.modal.show = false
      let fields = []
      forEach(itemStructure.fields, (field, index) => {
        fields.push({
          ...field,
          value: form[field.key]
        });
      })
      itemStructure = {
        ...itemStructure,
        fields: fields,
        show: true,
      }

      if (this.totalShowAbleItems(group) > 0) group.items.push(itemStructure)
      else group.items = [itemStructure]

      this.saveGroupItem(group)
    },

    async multiItemAddItem(field, form){
      let multiItem = {
        field: field,
        value: form[field]
      }

      let newForm = {
        full_name: this.profile.full_name,
        profile_items:[multiItem]
      }

      try {
        let res;
        if(!this.isCompanyModule()) res = await this.updateContactProfile(this.profile.contact_profile_id, newForm, this.profile.is_manual)
        else res = await this.updateCompanyContactProfile(this.$route.params.companyId, this.profile.contact_profile_id, newForm, this.profile.is_manual)

        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.$emit('profileUpdated')
          this.showSuccess(res.message)
        }
      } catch (err) {
        console.error(err)
        if (!err.response) {
          return this.showError('Something is wrong while adding multi item.!!')
        }
        if (err.response) {
          return this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },

    async updateItemVisibility(item){
      if(item.fields.length > 0){
        let itemId = [];
        let is_public = !item.fields[0].is_public;

        forEach(item.fields, (field) => {
          itemId.push(field.id)
        })

        let form = itemId.map((fieldId) => {
          return {
            contact_profile_item_id: fieldId,
            is_public: is_public,
          }
        })

        try{
          let res = await this.updateProfileItemVisibility({
            items: form
          })
          if(!res.status) this.showError(res.message)
          if(res.status) {
            this.$emit('profileUpdated')
            this.showSuccess(res.message)
          }
        }catch (e) {
          console.error(e)
          this.showError("something went wrong")
        }

      }
    },

    async saveGroupItem(groupItem) {
      let form = this.convGroupItemToSaveFormReq(this.profile, groupItem)
      this.loading = true;
      try {
        let res
        if(!this.isCompanyModule()) res = await this.updateContactProfile(this.profile.contact_profile_id, form, this.profile.is_manual)
        else res = await this.updateCompanyContactProfile(this.$route.params.companyId, this.profile.contact_profile_id, form, this.profile.is_manual)

        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.$emit('profileUpdated')
          this.showSuccess(res.message)
        }
      } catch (err) {
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response) {
          return this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }

    },

    async deleteItem(groupItem, itemIndex){
      let group = groupItem.items[itemIndex]
      let removeItems = this.getFieldsIdFromGroupItem(group)

      if(removeItems.includes(undefined) || removeItems.includes(null)){
        this.removeItemsFromGroupItem(groupItem, itemIndex)
        return false;
      }

      let formattedProfileItems = this.convGroupItemToSaveFormReq(this.profile, groupItem)
      formattedProfileItems['remove_items'] = removeItems

      this.loading = true;
      try {
        let res
        if(!this.isCompanyModule()) res = await this.updateContactProfile(this.profile.contact_profile_id, formattedProfileItems, this.profile.is_manual)
        else res = await this.updateCompanyContactProfile(this.$route.params.companyId, this.profile.contact_profile_id, formattedProfileItems, this.profile.is_manual)

        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.showSuccess(res.message)
          this.removeItemsFromGroupItem(groupItem, itemIndex)
          this.$emit('profileUpdated')
        }
      } catch (err) {
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response) {
          return this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }

    }

  },
  mounted() {
    this.contactProfile.multiItems.groupItems = this.groupItemsInitData
    this.updateProfileInfo();
  },
  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      groupItemsInitData,
    } = handleInitData()

    const {
      convGroupItemToSaveFormReq,
      totalShowAbleItems,
      formatProfileItemsByRef,
      getFieldsIdFromGroupItem,
      removeItemsFromGroupItem,
      isProfileEditable,
      isPublishable,
    } = handleHelpers()

    const {
      updateContactProfile,
      updateCompanyContactProfile,
      updateProfileItemVisibility
    } = handleContact()

    return {
      updateProfileItemVisibility,
      updateCompanyContactProfile,
      updateContactProfile,

      removeItemsFromGroupItem,

      isProfileEditable,
      isPublishable,
      formatProfileItemsByRef,
      convGroupItemToSaveFormReq,

      totalShowAbleItems,
      getFieldsIdFromGroupItem,
      groupItemsInitData,
      showError,
      showSuccess,
    }
  },
}
</script>

<style scoped>

</style>
