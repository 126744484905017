<template>
  <template v-if="totalShowAbleItems(groupItem) > 0" v-for="(item, itemIndex) in groupItem.items">
    <div class="col-xs-12 col-md-12 mb-75 border mt-1 py-1" v-if="item.show">
      <div class="row">
        <div class="col-xs-3 col-sm-3 d-flex">
          <img :src="item.logo !== ''?item.logo:'/app-assets/images/institute.jpg'" class="img-fluid align-middle p-4 contact-image-mobile">
        </div>
        <div class="col-xs-9 col-sm-9">
          <div class="row">

            <!-- Generate All Fields of Items -->
            <template v-for="(field,fieldIndex) in item.fields">
              <div class="col-xs-12 col-md-12">
                <label class="form-label">{{ field.title }}</label>
                <input
                    :class="{'is-invalid' : false}"
                    type="text"
                    class="form-control rounded-0"
                    :placeholder="field.placeholder?field.placeholder:`Enter ${field.title}`"
                    :value="groupItem.items[itemIndex].fields[fieldIndex].value"
                    disabled
                >
              </div>
            </template>

            <template v-if="isEditable || isDeletable || isPublishable">
              <div class="col-xs-12 col-md-12 mt-2" v-if="isEditable || isDeletable">
                <button
                    v-if="isEditable"
                    type="button"
                    class="btn btn-icon rounded-0 btn-primary waves-effect waves-float waves-light"
                    title="Edit Information"
                    @click="openGroupItemEditModal(item, itemIndex)"
                >
                  Edit Info
                </button>


                <button
                    v-if="isDeletable"
                    type="button"
                    class="btn btn-icon rounded-0 btn-danger waves-effect waves-float waves-light ms-1"
                    title="Remove Item"
                    @click="$emit('itemDeleted', groupItem, itemIndex)"
                >
                  Remove Item
                </button>
                <div class="form-check form-check-success form-switch float-end" v-if="isPublishable">
                  <input
                      type="checkbox"
                      class="form-check-input"
                      :checked="item.fields[0].is_public"
                      @change="$emit('itemVisibilityUpdate', item, itemIndex)"
                  >
                </div>
              </div>
            </template>
            <div class="col-xs-12 col-md-12"><br></div>

          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="col-xs-12 col-md-12 mb-75">
      <div class="empty-item">
        <h4>No Items Found</h4>
        <h5 v-if="isEditable">Click Plus Icon Top To Add Item</h5>
      </div>
    </div>
  </template>


  <MultiItemGroupModal
      :isEditing="modal.isEditing"
      :show="modal.show"
      :fields="modal.fields"
      :heading="modal.heading"
      @closeModal="modal.show = false"
      @AddGroup="GroupItemUpdate"
  ></MultiItemGroupModal>
</template>

<script>
import {countBy, forEach, has} from "lodash";
import MultiItemGroupModal from "@/components/atom/Contact/MultiItemGroupModal";

export default {
  name: "MultiItemGroupComponent",
  emits: ['itemDeleted', 'groupItemUpdated', 'itemVisibilityUpdate'],
  components: {
    MultiItemGroupModal: MultiItemGroupModal
  },
  props:{
    groupItem: {
      required: true
    },
    isEditable: {
      default: false,
      type: Boolean,
    },
    isPublishable: {
      default: false,
      type: Boolean,
    },
    isDeletable: {
      default: false,
      type: Boolean,
    },
    isLoginUserProfile: {
      default: false,
      type: Boolean,
    }
  },
  data: () => ({
    modal: {
      isEditing: true,
      show: false,
      fields: [],
      heading: "Update Item",
      groupItemIndex: 0,
      itemIndex: 0,
    },

    //Structure That Should Be Followed
/*    multiItem: {
      key: 'education',
      heading: 'Education',
      items: [
        {
          logo: '/app-assets/images/institute.jpg',
          show: false,
          fields: [
            {
              key: 'institute',
              title: 'Institute',
              value: '',
            },
            {
              key: 'institute_location',
              title: 'Institute Location',
              value: '',
            }
          ],
        }
      ],
    },*/


  }),
  methods: {
    totalShowAbleItems(mulItem){
      return countBy(mulItem.items, obj => !!obj.show)["true"]
    },
    openGroupItemEditModal(item, itemIndex) {
      this.modal.itemIndex = itemIndex
      this.modal.fields = item.fields
      this.modal.show = true
    },
    GroupItemUpdate(form){
      this.modal.show = false
      forEach(this.groupItem.items[this.modal.itemIndex].fields, (item, index) => {
        if(has(form, item.key)) item.value = form[item.key]
      })
      this.$emit('groupItemUpdated', this.groupItem)
    }
  },
}
</script>

<style scoped>
.form-control:disabled {
  background-color: white;
}

.empty-item{
  border: 2px dotted #7367f0;
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
