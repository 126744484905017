<template>
  <div class="fade text-start modal-primary"
       id="primary"
       tabindex="-1"
       style="display: block; background: rgba(28, 29, 28, 0.2)"
       :class="{'modal': show, 'show': show}"
       v-if="show"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <vForm @submit="$emit('basicInfoSubmitted', form)" :validation-schema="schema">
          <div class="modal-header">
            <h3 class="modal-title">Basic Info Edit</h3>
            <button type="button" class="btn-close" @click="$emit('closeModal')"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="(field, fieldIndex) in fields">
                <div class="col-xs-12 col-sm-12">
                  <label
                      class="form-label mt-1"
                      :class="{required: isRequired(field.key)}"
                  >{{ field.title }}</label>
                  <vField
                      :class="{'is-invalid' : false}"
                      :type="field.type"
                      class="form-control rounded-0"
                      v-model="form[field.key]"
                      :name="field.key"
                  >
                  </vField>
                  <ErrorMessage :name="field.key" class="invalid-feedback d-block"/>
                </div>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary waves-effect waves-float waves-light">
              Update
            </button>
          </div>
        </vForm>

      </div>
    </div>
  </div>
</template>

<script>
import {forEach, has} from 'lodash'
import handleContact from "@/services/modules/contact";

export default {
  name: "BasicInfoEditModal",
  props: ['contactProfile', 'show'],
  data: () => ({
    fields: [],
    form: {},
    profileId: 0,
    attrs:{
      date_of_birth: {
        type: 'date',
      },
      full_name: {
        type: 'text',
        required: true,
      },
      company: {
        type: 'text',
        required: true,
      },
      job_title: {
        type: 'text',
        required: true,
      },
    },
    schema: {
      full_name: 'required',
      company: 'required|max:255',
      job_title: 'required|max:255',
      department: 'required|max:255',
      date_of_birth: 'required',
      nid: 'numeric',
    }
  }),
  watch: {
    contactProfile: {
      handler(contactProfile){
        this.adaptItems(contactProfile.items)
        this.profileId = contactProfile.contact_profile_id
      },
      deep: true,
    }
  },
  methods: {
    isRequired(key){
      return has(this.attrs, `${key}.required`)?this.attrs[key].required:false
    },
    adaptItems(items){
      let newFields = [];
      forEach(items, (item, key) => {
        newFields.push({
          key: key,
          value: item.value,
          title: item.field,
          type: has(this.attrs, key)?this.attrs[key].type:'text',
        })
        this.form[key] = item.value !== 'N/A'? item.value: ''
      })

      this.fields = newFields
    }
  },

  mounted() {
    this.adaptItems(this.contactProfile.items)
  },

  setup(){
    const {updateContactProfile} = handleContact()
    return {
      saveLoginUserContactProfile: updateContactProfile,
    }
  }

}
</script>

<style scoped>
  .form-label.required:after{
    content: "*";
    margin: 0;
    padding: 0;
    color: red;
  }
</style>