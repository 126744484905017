<template>
  <div class="row">

    <div class="col-md-12" v-for="item in items">
      <div class="input-group item-content mt-1">
        <input type="text" class="form-control border-0 rounded-0" disabled :value="item.value">

        <div class="switch mx-1 d-flex justify-content-center align-items-center" v-if="isPublishable">
          <div class="form-check form-check-success form-switch float-end">
            <input type="checkbox" class="form-check-input" :checked="item.is_public">
          </div>
        </div>

        <button class="rounded-0 ms-1 btn btn-danger btn-icon waves-effect waves-float waves-light" v-if="isDeletable">
          <i class="fas fa-minus"></i>
        </button>

      </div>
    </div>



    <div class="col-md-12" v-if="isEditable">
      <vForm @submit="addItem" :validation-schema="valSchema">
        <div class="input-group mt-1">
          <vField
            type="text"
            class="form-control rounded-0"
            v-model="form[field]"
            :name="field"
          ></vField>
          <button type="submit" class="rounded-0 ms-1 btn btn-primary btn-icon waves-effect waves-float waves-light">
            <i class="fas fa-plus"></i>
          </button>
        </div>
        <ErrorMessage :name="field" class="invalid-feedback d-block"/>
      </vForm>
    </div>


  </div>
</template>

<script>
export default {
  name: "MultiItemContent",
  emits: ['onItemAdd'],
  props:{
    items: {
      required: true
    },
    field: {
      required: true
    },
    isEditable: {
      default: false,
      type: Boolean,
    },
    isPublishable: {
      default: false,
      type: Boolean,
    },
    isDeletable: {
      default: false,
      type: Boolean,
    }
  },
  data: () => ({
    schemaConfig:{
      phone: 'required|numeric|max:11',
      email: 'required|email|max:255',
    },
    valSchema:{},
    form: {},
  }),
  methods:{
    addItem(reset){
      this.$emit('onItemAdd', this.field, this.form)
      this.init()
    },
    init(){
      this.valSchema = {}
      this.form = {}
      this.valSchema[this.field] = this.schemaConfig[this.field]
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>

.input-group.item-content{
  background-color: inherit;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.form-control{
  background-color: inherit;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none;
  border-radius: 0.357rem;
}

.input-group.item-content:not(.bootstrap-touchspin):focus-within .form-control, .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #d8d6de;
  box-shadow: none;
  background-color: inherit;
}
</style>