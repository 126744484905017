<template>
  <div v-if="!isLoading">
    <ContactBanner
        :name="contactProfile.full_name??''"
        :profilePhoto="contactProfile.photo??''"
        :coverPhoto="contactProfile.cover_photo??''"
        :showProfilePhoto="true"
        :isEditable="isBannerEditable"
        :group="group"
        @photoUploaded="uploadPhoto"
    ></ContactBanner>
    <div class="row">
      <div class="col-12 col-md-10 col-lg-11 col-xl-8 m-auto">

        <div class="card" v-if="contactProfile.is_manual == 1">
          <div class="card-header pb-0"><h1>Select Contact Type</h1></div>
          <div class="card-content pb-1">
            <div class="card-body">
              <div class="row pb-2">
                <div class="col-xs-12 col-sm-12">
                  <div class="mb-xs-3">
                    <div class="search-container position-relative p-0 m-0 position-relative">
                      <div class="input-group input-group-merge">
                        <select name="" id="" class="form-control" v-model="profileGroup" @change="updateGroup">
                          <option value="0">Select Profile Group</option>
                          <option value="customer">Customer</option>
                          <option value="supplier">Supplier</option>
                          <option value="employee">Employee</option>
                          <option value="member">Member</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ContactMyProfileBasicInfo
            :profile="getProfileData"
            :isLoginUserProfile="isLoginUserProfile"
            @basicInfoUpdated="getDetails"
        ></ContactMyProfileBasicInfo>
        <ContactProfileMultiItemInfo
            :profile="getProfileData"
            :isLoginUserProfile="isLoginUserProfile"
            @profileUpdated="getDetails"
        ></ContactProfileMultiItemInfo>
      </div>
    </div>
  </div>
  <div v-else>
    <Loader></Loader>
  </div>
</template>

<script>

import {inject} from "vue";
import handleContact, {handleHelpers} from "@/services/modules/contact";
import ContactMyProfileBasicInfo from "@/components/molecule/contact/ContactMyProfileBasicInfo";
import ContactProfileMultiItemInfo from "@/components/molecule/contact/ContactProfileMultiItemInfo";
import MultiItemGroupModal from "@/components/atom/Contact/MultiItemGroupModal";
import ContactBanner from "@/components/atom/Contact/ContactBanner";
import {has, isEmpty} from "lodash";
import LoaderComponent from "@/components/atom/LoaderComponent";
import handleProfile from "@/services/modules/profile";

export default {
  name: 'ContactProfileMain',
  emits:['onUpdateContactGroup', 'onProfileUpdate'],
  props: {
    contactProfile: {
      default: {},
      type: Object
    },
    group: {
      default: '',
    },
    isLoginUserProfile: {
      default: false,
      type: Boolean
    }
  },
  components: {
    ContactMyProfileBasicInfo: ContactMyProfileBasicInfo,
    ContactProfileMultiItemInfo: ContactProfileMultiItemInfo,
    MultiItemGroupModal: MultiItemGroupModal,
    ContactBanner: ContactBanner,
    Loader: LoaderComponent,
  },
  data() {
    return {
      ignore: [
        'contact_profile_id',
        'photo',
        'cover_photo',
        'is_manual',
        'location'
      ],
      profileGroup: ''
    }
  },
  watch:{
    group(){
      if(!!this.group) this.profileGroup = this.group
      else this.profileGroup = 0
    },
  },
  computed:{
    getProfileData(){
      return this.contactProfile
    },
    isBannerEditable(){
      return this.isProfileEditable(this.contactProfile)
    },
    isLoading(){
      return isEmpty(this.contactProfile)
    },
  },
  methods: {
    updateGroup(){
      this.$emit('onUpdateContactGroup', this.profileGroup)
    },
    async uploadPhoto(image, type){
      let res;
      this.loading = true;
      try{
        let form = new FormData();
        form.append(type, image)
        form.append('_method', 'patch')

        switch (has(this.$route.params,'companyId')) {
          case true:
            if(this.contactProfile.is_manual == 1) res = await this.updateManualCompanyContactProfilePhoto(this.$route.params.companyId, this.contactProfile.contact_profile_id, form)
            else res = await this.updateCompanyContactProfilePhoto(this.$route.params.companyId, this.contactProfile.contact_profile_id, form)
            break
          default:
            if(this.contactProfile.is_manual == 1) res = await this.updateManualContactProfilePhoto(this.contactProfile.contact_profile_id, form)
            else res = await this.updateContactProfilePhoto(form)
        }

        if(res.status) {
          await this.getDetails();
          this.showSuccess(res.message)
        }
        if(!res.status) this.showError(res.message)
      }catch (e) {
        this.showError('Something went wrong')
      }finally {
        this.loading = false
      }
    },
    reformatData(data) {
      let item, newData = {};
      for (item in data.contact_profile.contact_profile_items) {
        if (this.ignore.includes(item)) continue
        newData[item] = {
          field: this.contactProfile.items[item].field,
          value: data.contact_profile.contact_profile_items[item][0].value,
        }
      }
      for (item in data.contact_profile) {
        if (item !== 'contact_profile_items' && item !== 'location' && !this.ignore.includes(item)) {
          newData[item] = {
            field: this.contactProfile.items[item].field,
            value: data.contact_profile[item],
          }
        }
      }

      for (item in data.contact_profile.location) {
        if (this.ignore.includes(item)) continue
        newData[item] = {
          field: this.contactProfile.items[item].field,
          value: data.contact_profile.location[item],
        }
      }

      this.contactProfile.items = newData
    },
    formatSingleItem(item, field) {
      let obj = item.contact_profile.contact_profile_items;
      if (obj !== null && (field in obj) && obj.hasOwnProperty(field)) return obj[field][0].value
      return 'N/A'
    },

    async getDetails(showSuccessMessage = false) {
      this.$emit('profileUpdate', showSuccessMessage)
    },
  },
  mounted() {


  },
  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const  {
      fetchProfileInfo,
    } = handleProfile()

    const {
      isProfileEditable,
    } = handleHelpers()

    const {
      getLoginUserProfile,
      updateContactProfilePhoto,
      updateManualContactProfilePhoto,
      updateCompanyContactProfilePhoto,
      updateManualCompanyContactProfilePhoto,
      loading,
    } = handleContact()

    return {
      getLoginUserProfile,
      fetchProfileInfo,

      updateContactProfilePhoto,
      updateManualContactProfilePhoto,
      updateCompanyContactProfilePhoto,
      updateManualCompanyContactProfilePhoto,

      isProfileEditable,
      loading,
      showError,
      showSuccess
    }
  }
}
</script>
