<template>
  <template v-for="(content, key, index) in contents" :key="key">
    <div class="card" v-if="contents.hasOwnProperty(key)">
      <div class="card-header">
        <h4 class="card-title">{{ items[key].title }}</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-xs-12 col-md-12 mb-75 mt-1 py-1">
            <div class="row">
              <div class="col-xs-3 col-sm-3">
                <img :src="items[key].logo" class="img-fluid align-middle p-4 contact-image-mobile">
              </div>
              <div class="col-xs-9 col-sm-9">
                <MultiItemContent
                    :items="content"
                    :field="key"
                    :isEditable="isEditable"
                    :isDeletable="isDeletable"
                    :isPublishable="isPublishable"
                    @onItemAdd="AddItem"
                ></MultiItemContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </template>
</template>

<script>
import {countBy, has} from "lodash";
import MultiItemContent from "@/components/atom/Contact/MultiItemContent";
import {forEach} from "lodash";
import {handleHelpers} from "@/services/modules/contact";

export default {
  name: "MultiItem",
  emits: ['onAddItem'],
  components:{
    MultiItemContent: MultiItemContent
  },
  props:{
    multiItem: {
      required: true
    },
    isEditable: {
      default: false,
      type: Boolean,
    },
    isPublishable: {
      default: false,
      type: Boolean,
    },
    isDeletable: {
      default: false,
      type: Boolean,
    }
  },
  data: () => ({
    items: {
      'phone': {
        'title': 'Phone',
        'logo': '/app-assets/images/icons/telephone.png',

      },
      'email': {
        'title': 'Email',
        'logo': '/app-assets/images/icons/email.png',
      },
    },
    contents: [],
  }),
  methods:{
    totalShowAbleItems(mulItem){
      return countBy(mulItem.items, obj => !!obj.show)["true"]
    },
    initContent(){
      let contents = {}
      forEach(this.items, (item, key) => {
        if(has(this.multiItem, key)) contents[key] = this.multiItem[key]
      })
      this.contents = contents
    },
    AddItem(field, form){
      this.$emit('onAddItem', field, form);
    },
    saveGroupItem(){},
    updateItemVisibility(){},
  },

  watch:{
    multiItem:{
      handler() {
        this.initContent()
      },
      deep: true,
    }
  },

  mounted() {
    this.initContent()
  },

  setup(){
    const {
      formatProfileItemsByRef
    } = handleHelpers()
    return {
      formatProfileItemsByRef
    }
  }
}
</script>

<style scoped>

</style>