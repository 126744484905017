<template>
  <div class="fade text-start modal-primary"
       id="primary"
       tabindex="-1"
       style="display: block; background: rgba(28, 29, 28, 0.2)"
       :class="{'modal': show, 'show': show}"
       v-if="show"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <vForm @submit="submitForm" :validation-schema="schema" v-slot="{errors}" mode="aggressive">
          <div class="modal-header">
            <h3 class="modal-title">{{ heading }}</h3>
            <button type="button" class="btn-close" @click="$emit('CloseModal')"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="(field, fieldIndex) in fields">
                <div class="col-xs-12 col-sm-12">
                  <label class="form-label">{{ field.title }}</label>
                  <vField
                      :class="{'is-invalid' : false}"
                      type="text"
                      class="form-control rounded-0"
                      v-model="form[field.key]"
                      :name="field.key"
                  >
                  </vField>
                  <ErrorMessage :name="field.key" class="invalid-feedback d-block mb-1"/>
                </div>
              </template>

            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary waves-effect waves-float waves-light">
              {{ actionBtnTxt }}
            </button>
          </div>
        </vForm>

      </div>
    </div>
  </div>
</template>

<script>
import {forEach} from "lodash";

export default {
  name: "MultiItemGroupModal",
  props: {
    fields:{
      required: true,
      type: Array,
    },
    heading:{
      required: true,
      type: String,
    },
    show:{
      default: false,
      type: Boolean,
    },
    isEditing:{
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    schema: {},
    form: {}
  }),
  computed:{
    actionBtnTxt(){
      return this.isEditing?'Update Item':'Add To Group'
    }
  },
  methods:{
    submitForm(){
      let formData = this.form
      this.form = {}
      this.$emit('AddGroup', formData)
    }
  },
  watch:{
    fields:{
      handler(){
        this.schema = {}
        this.fields.forEach((item) => {
          this.schema[item.key] = 'required|max:255'
          if(this.isEditing) this.form[item.key] = item.value??''
        })
      },
      deep: true
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
